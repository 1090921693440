import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Disclosure } from '../blogsupport/Disclosure.js';
import BlogBttmDsktp from '../components/Advertising/BlogBttmDsktp';
import BlogBttmMob from '../components/Advertising/BlogBttmMob';
import BlogTopDsktp from '../components/Advertising/BlogTopDsktp';
import Halfpage from '../components/Advertising/Halfpage';
import Sidebar1Desktop from '../components/Advertising/Sidebar1Desktop';
import TopBlogMob from '../components/Advertising/TopBlogMob';
import AuthorName from '../components/AuthorName';
import BookmarkWidget2 from '../components/BookmarkWidget2';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import SeoArticle from '../components/SEO/SeoArticle';
import PostOptin from '../components/Subscribe/PostOptin';
import Top5Recipes from '../components/Top5Recipes';
import Top5PostsImage from '../components/Top5PostsImage';
import Verificado from '../components/Verificado';
import { GatsbyImage } from 'gatsby-plugin-image';
import Avatar from '../components/Avatar';
import SliceZone from '../components/SliceZone';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: 250,
    marginBottom: '1rem',
    '@media (min-width: 768px)': {
      marginRight: '1.5rem',
      maxWidth: 'calc(31.5%)',

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const MainBody = styled.div`
  a {
    color: #eb4a4b;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const PostTemplate = ({ data }) => {
  const { pageViews, PostData, theAuthor } = data;

  // const timeRead = Math.round(minutes);
  const classes = useStyles();
  //const expert = experts.filter(expert => expert.id === expertID);

  //const imageFB2 = imageFB ? `${imageFB.childImageSharp.gatsbyImageData.images.fallback.src}` : ""

  const imageFeatured = PostData.data.featured_image
    ? `${PostData.data.featured_image.url}`
    : '';

  // const imageFacebook = (imageFB2 == "" && imageFeatured != "") ? imageFeatured : imageFB2 ;
  PostData.data.author.document.slug = PostData.data.author.slug
    ? PostData.data.author.slug
    : '';

  const fullPost = (
    <div>
      <div className="grid grid-cols-blog w-full container mx-auto gap-x-4">
        <article className="col-span-2 md:col-span-1 mx-autoleading-losse my-6 xl:px-24">
          <div className="flex flex-col min-h-64 bg-white p-6 md:px-6 lg:px-12">
            <div className="mt-6 flex flex-row gap-2">
              <span className="capitalize">{PostData.data.release_date}</span>
              <div>
                {PostData.data.categories.map((category, i) => {
                  return (
                    <div className=" inline-block" key={i}>
                      <Link
                        to={`/diabetes/${category.category.slug}/`}
                        className="border border-themeBrandColor rounded hover:bg-themeBrandColor text-themeBrandColor hover:text-white px-2 mr-3 whitespace-nowrap"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {category.category.document.data.label.text}
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-col flex-wrap">
              <div className="sm:flex py-2">
                <h1 className="leading-none capitalize">
                  {PostData.data.title.text}
                </h1>
                {PostData.data.verified ? <Verificado /> : ''}
              </div>

              <div className="sm:flex items-center clear-both mb-6">
                <Avatar
                  authorPic={theAuthor.data.headshot.gatsbyImageData}
                  authorName={PostData.data.author.document}
                />

                <div className="flex flex-col mt-2 sm:mt-0">
                  <div className="leading-6 mt-6">
                    {PostData.data.author.document ? (
                      <AuthorName author={PostData.data.author.document} />
                    ) : (
                      <span>Dediabetes</span>
                    )}

                    {/* <span className="italic"> • {timeRead}min</span> */}
                    <span>
                      {pageViews && pageViews.allTimeViews
                        ? ` • ${pageViews.allTimeViews} views`
                        : ''}
                    </span>
                  </div>
                  {PostData.data.verified ? (
                    <p className="mt-0 leading-6">
                      Revisado por{' '}
                      <Link
                        to="/experts/"
                        className="underline hover:text-red-600"
                      >
                        {PostData.data.expert.document.data.name.text}
                        {PostData.data.expert.document.data.certifications &&
                          `, ${PostData.data.expert.document.data.certifications}`}
                      </Link>
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="flex flex-row leading-tight">
                <BookmarkWidget2
                  slug={PostData.uid}
                  title={PostData.data.title.text}
                />
              </div>
            </div>
          </div>

          {PostData.data.featured_image.gatsbyImageData && (
            <div className="overflow-hidden leading-3 pb-2 md:ml-6 lg:mx-20">
              <GatsbyImage
                image={PostData.data.featured_image.gatsbyImageData}
                alt={PostData.data.title.text}
              />
            </div>
          )}

          <div className="md:flex flex-row justify-center mt-6 -ml-6 hidden md:visible clear-both">
            <BlogTopDsktp />
          </div>
          <div className="flex flex-row justify-center mt-6 md:hidden ">
            <TopBlogMob />
          </div>

          <div className="clear-both">
            <MainBody className="-mt-4 px-4 md:px-6 lg:px-12 bg-white">
              <div className="flex flex-row justify-center mt-6">
                <PostOptin />
              </div>
              <SliceZone allSlices={PostData.data.body} />
            </MainBody>
            <div className="md:px-6 lg:px-12 mt-8 px-2 leading-tight">
              <BookmarkWidget2
                slug={PostData.uid}
                title={PostData.data.title.text}
              />
            </div>

            <Disclosure />
          </div>
          <div className="md:flex flex-row justify-center -ml-6 hidden md:visible">
            <BlogBttmDsktp />
          </div>
          <div className="flex flex-row justify-center mt-6 md:hidden ">
            <BlogBttmMob />
          </div>
        </article>
        <aside className=" hidden md:block md:visible mx-autoleading-losse bg-white my-6">
          <div className="">
            <Sidebar1Desktop />
          </div>

          <Top5Recipes />
          <div className="my-3">
            <Halfpage />
          </div>
        </aside>
      </div>
      <section className="mt-6 border-t-8 border-themeBrandColor">
        <h2 className="text-center my-8">Artículos Relacionados</h2>
        <div className="m-2 mt-4 sm:mx-4 flex flex-col md:flex-row flex-wrap lg:flex-nowrap justify-center  max-w-full-xl xl:mx-auto">
          {PostData.data.related_articles_group &&
            PostData.data.related_articles_group.map((related, i) => {
              if (related.article.document) {
                return (
                  <Card className={classes.root} key={i}>
                    <CardContent>
                      <Link to={`/${related.article.document.uid}`}>
                        <h3 className="mb-4">
                          {related.article.document.data.title.text}
                        </h3>
                      </Link>
                      <p className="m-0">
                        {related.article.document.data.description.text}
                      </p>
                    </CardContent>
                    <CardActions>
                      <Link to={`/${related.article.document.uid}`}>
                        <Button size="small">Sigue leyendo</Button>
                      </Link>
                    </CardActions>
                  </Card>
                );
              }
              return null;
            })}
        </div>
      </section>
      <Top5PostsImage />
    </div>
  );

  return (
    <Layout>
      <SeoArticle
        title={`${PostData.data.title.text}`}
        date={PostData.data.release_date}
        description={`${PostData.data.description.text}`}
        article={true}
        lastmodified={PostData.last_publication_date}
        language={PostData.lang}
        featuredImage={imageFeatured}
        authorData={PostData.data.author.document}
        authorImage={theAuthor.data.headshot}
      />
      <Hero />

      {fullPost}
    </Layout>
  );
};

export const query = graphql`
  query postQueryEn($slug: String, $fullSlug: String, $author: String) {
    PostData: prismicBlogPost(
      lang: { eq: "en-us" }
      data: { author: { uid: { ne: null } } }
      uid: { eq: $slug }
    ) {
      uid
      data {
        verified
        related_articles_group {
          article {
            document {
              ... on PrismicBlogPost {
                id
                uid
                data {
                  title {
                    text
                  }
                  description {
                    text
                    html
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicBlogPostDataBodyVideoBlock {
            id
            slice_type
            primary {
              video {
                text
                html
              }
            }
          }
          ... on PrismicBlogPostDataBodyQuote {
            id
            slice_type
            primary {
              quote {
                text
                html
              }
            }
          }
          ... on PrismicBlogPostDataBodyWarningBox {
            id
            slice_type
            primary {
              warning {
                html
                text
              }
            }
          }
          ... on PrismicBlogPostDataBodyInfoBox {
            id
            slice_type
            primary {
              heading {
                text
                html
              }
              warning {
                text
                html
              }
            }
          }
          ... on PrismicBlogPostDataBodySuccessBox {
            id
            slice_type
            primary {
              warning {
                text
                html
              }
            }
          }
          ... on PrismicBlogPostDataBodyAlertText {
            id
            slice_type
            primary {
              alert_content {
                text
                html
              }
            }
          }
          ... on PrismicBlogPostDataBodyTable6Col {
            id
            slice_type
            primary {
              column_1_heading
              column_2_heading
              column_3_heading
              column_4_heading
              column_5_heading
              column_6_heading
            }
            items {
              col1 {
                html
              }
              col2 {
                html
              }
              col3 {
                html
              }
              col4 {
                html
              }
              col5 {
                html
              }
              col6 {
                html
              }
            }
          }
          ... on PrismicBlogPostDataBodyTable5Col {
            id
            slice_type
            primary {
              column_1_heading
              column_2_heading
              column_3_heading
              column_4_heading
              column_5_heading
            }
            items {
              col1 {
                html
              }
              col2 {
                html
              }
              col3 {
                html
              }
              col4 {
                html
              }
              col5 {
                html
              }
            }
          }
          ... on PrismicBlogPostDataBodyTable4col {
            id
            slice_type
            primary {
              column_1_heading
              column_2_heading
              column_3_heading
              column_4_heading
              table_title {
                html
                text
              }
            }
            items {
              col1 {
                html
              }
              col2 {
                html
              }
              col3 {
                html
              }
              col4 {
                html
              }
            }
          }
          ... on PrismicBlogPostDataBodyTable3col {
            id
            slice_type
            primary {
              column_1_heading
              column_2_heading
              column_3_heading
              table_title {
                html
                text
              }
            }
            items {
              col1 {
                html
              }
              col2 {
                html
              }
              col3 {
                html
              }
            }
          }
          ... on PrismicBlogPostDataBodyTable {
            id
            slice_type
            primary {
              column_1_heading
              column_2_heading
              table_title {
                html
                text
              }
            }
            items {
              col1 {
                html
              }
              col2 {
                html
              }
            }
          }
          ... on PrismicBlogPostDataBodyCustomTable {
            id
            slice_type
            primary {
              table_content {
                html
                text
              }
            }
          }
          ... on PrismicBlogPostDataBodyPostoptin {
            id
            slice_type
            primary {
              post_opt
            }
          }
          ... on PrismicBlogPostDataBodyText {
            id
            slice_type
            primary {
              text {
                html
                text
              }
            }
          }

          ... on PrismicBlogPostDataBodyAmazonProduct {
            id
            slice_label
            slice_type
            primary {
              product_image {
                gatsbyImageData
                alt
              }
              product_link {
                url
              }
              product_name {
                text
              }
            }
          }

          ... on PrismicBlogPostDataBodyImage {
            id
            slice_type
            primary {
              image_alt_text
              image {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
        expert {
          document {
            ... on PrismicExpert {
              id
              data {
                name {
                  text
                }
                certifications
              }
            }
          }
        }
        title {
          text
        }
        release_date(formatString: "MMM DD, YYYY", locale: "es-ES")
        description {
          text
        }
        featured_image {
          gatsbyImageData(layout: CONSTRAINED)
          url
        }
        categories {
          category {
            slug: uid
            document {
              ... on PrismicCatego {
                id
                data {
                  name {
                    text
                  }
                  label {
                    text
                  }
                }
              }
            }
          }
        }
        author {
          slug: uid
          document {
            ... on PrismicAuthor {
              id
              data {
                name {
                  text
                }
                description_short {
                  text
                }
                linkedin {
                  url
                }
                twitter {
                  url
                }
              }
            }
          }
        }
      }
      last_publication_date(formatString: "MMM DD, YYYY", locale: "es-ES")
      first_publication_date(formatString: "MMM DD, YYYY", locale: "es-ES")
      lang
    }
    theAuthor: prismicAuthor(uid: { eq: $author }) {
      data {
        headshot {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    deAuthor: prismicAuthor(uid: { eq: "dediabetes" }) {
      data {
        name {
          text
        }
        description_short {
          text
        }
        headshot {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    pageViews(id: { eq: $fullSlug }) {
      allTimeViews
    }
  }
`;

export default PostTemplate;

SeoArticle.propTypes = {
  src: PropTypes.string,
  imageFacebook: PropTypes.string,
  imageFeatured: PropTypes.string,
};

SeoArticle.defaultProps = {
  src: null,
  imageFacebook: null,
  imageFeatured: null,
};
