import React from 'react';
import { useDfpSlot } from '../useDfpSlot';

const BlogBttmDsktp = () => {
  useDfpSlot({
    path: `/267424269/dd-BttmBlogDsktp`,
    size: [728, 90],
    id: 'div-gpt-ad-1624050622267-0',
  });
  return (
    <div>
      {/* <p className="text-center text-sm -mb-2">Anuncio</p> */}
<div
      id="div-gpt-ad-1624050622267-0"
      style={{ width: '728px', height: '90px' }}
    />
    </div>

  );
};

export default BlogBttmDsktp;
