import React from 'react';
import MessagePanel from './MessagePanel';
import { useSendForm } from '../../utils/useSendForm';

const PostOptin = () => {
  const {
    email,
    sending,
    error,
    success,
    onEmailChange,
    submitForm,
  } = useSendForm();

  return (
    <section className="text-gray-600 body-font bg-themeLighterAlt mt-0 p-2">
      <div className="container py-2 mx-auto">
        <p className="mx-auto leading-relaxed text-base text-center my-2 px-6">
          Subscríbete para recibir un plan de comidas para la diabetes de 7
          dias,&nbsp;
          <u>
            <b>gratis</b>
          </u>
        </p>

        <form
          action=""
          className="flex lg:w-2/3 w-full lg:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-2 space-y-4 sm:px-2 items-center md:items-end"
          onSubmit={submitForm}
        >
          <div className="relative flex-grow w-full pl-2">
            <input
              className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-transparent focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-2 leading-8 transition-colors duration-200 ease-in-out"
              type="email"
              name="email"
              id="email-input"
              placeholder="tucorreo@dominio.com"
              value={email}
              onChange={onEmailChange}
              disabled={sending}
            />
          </div>

          <button
            className="w-full lg:w-1/3 rounded border-2 border-themeBrandColor border-solid bg-themeBrandColor hover:bg-white text-white hover:text-themeBrandColor focus:outline-none rounded py-1 mr-2 px-2 text-lg"
            type="submit"
          >
            Suscribirme
          </button>
        </form>

        <div className="flex lg:w-2/3 w-full lg:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-2 space-y-4 sm:px-2 items-center md:items-end">
          <MessagePanel error={error} sending={sending} success={success} />
        </div>
      </div>
    </section>
  );
};

export default PostOptin;
