import React from 'react';

export default function BookmarkWidget2({ slug, recipe, title }) {
  const urlPart = recipe ? `recetas/${slug}` : slug;

  const emaillink = `mailto:?&subject=${title}%20-%20dediabetes.com&cc=&bcc=&body=https://www.dediabetes.com/${urlPart}`;
  const facebooklink = `https://www.facebook.com/sharer/sharer.php?u=https://dediabetes.com/${urlPart}`;
  const textfortwitter = `${title} @dediabetes`;
  const twitterlink = `https://twitter.com/intent/tweet?url=https://www.dediabetes.com/${urlPart}&text=%20${textfortwitter}%20%23tag1%20%23tag2`;

  return (
    <div className="flex flex-row items-center">
      <button
        target="popup"
        className="focus:outline-none mr-4"
        onClick={() => {
          typeof window !== 'undefined' &&
            window.open(facebooklink, 'popup', 'width=600,height=600');
          return false;
        }}
      >
        <span className="text-gray-500 ">
          <svg
            fill="currentColor"
            className="w-5 h-5"
            strokeWidth="0"
            viewBox="0 0 24 24"
          >
            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
          </svg>
        </span>
      </button>
      <button
        className="focus:outline-none mr-4"
        onClick={() => {
          typeof window !== 'undefined' &&
            window.open(twitterlink, 'popup', 'width=600,height=600');
          return false;
        }}
      >
        <span className="text-gray-500">
          <svg fill="currentColor" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
          </svg>
        </span>
      </button>
      <span className="text-gray-500 mr-4">
        <a href={emaillink}>
          <svg fill="currentColor" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M1.75 3A1.75 1.75 0 000 4.75v14c0 .966.784 1.75 1.75 1.75h20.5A1.75 1.75 0 0024 18.75v-14A1.75 1.75 0 0022.25 3H1.75zM1.5 4.75a.25.25 0 01.25-.25h20.5a.25.25 0 01.25.25v.852l-10.36 7a.25.25 0 01-.28 0l-10.36-7V4.75zm0 2.662V18.75c0 .138.112.25.25.25h20.5a.25.25 0 00.25-.25V7.412l-9.52 6.433c-.592.4-1.368.4-1.96 0L1.5 7.412z"></path>
          </svg>
        </a>
      </span>
    </div>
  );
}
