import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';
import PostDisplay from './PostDisplay';

const Heading = styled.div`
width: 10rem;

@media (min-width: 640px) {
  width: 11rem;
}

@media (min-width: 768px) {
  width: 14.5rem;
}
`;

const HeroBox = () => {
  const herodata = useStaticQuery(
    graphql`
      query {
        categoryPosts : allPrismicBlogPost(filter: {data: {author: {uid: {ne: null}}, featured: {eq: true}}, lang: {eq: "es-es"}}){
          edges {
            node {
              uid
              data {
                featured
                title {
                  text
                  html
                }
                type
                release_date(formatString: "MMM DD, YYYY", locale: "es-ES")
                author {
                  slug: uid
                  document {
                    ... on PrismicAuthor {
                      id
                      uid
                      data {
                        name {
                          text
                        }
                        description_short {
                          text
                        }
                        headshot {
                          gatsbyImageData(layout: CONSTRAINED)
                        }
                      }
                    }
                  }
                }
                categories {
                  category {
                    slug: uid
                    document {
                      ... on PrismicCatego {
                        id
                        uid
                        data {
                          name {
                            text
                          }
                          label {
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        deAuthor : prismicAuthor(uid: {eq: "dediabetes"}) {
          data {
            name {
              text
            }
            description_short {
              text
            }
            headshot {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    `
  );
  const { categoryPosts, deAuthor } = herodata;
  const posts = categoryPosts.edges.map(post => {
    if(!post.node.data.author.document){
      post.node.data.author = deAuthor
      post.node.data.author.slug = 'dediabetes'
    }
    else
    {
      let author_id = post.node.data.author.slug
      post.node.data.author = post.node.data.author.document
      post.node.data.author.slug = author_id
    }
    return post
  })
  return (
    <div className="flex">
      <Heading className="bg-themeBrandColor p-4 sm:p-6 flex">
        <h2 className="m-auto text-white text-lg">Tema de la Semana</h2>
      </Heading>
      <ScrollContainer className="flex" vertical={false}>
         {posts.map(post => {
            return <PostDisplay key={post.id} post={post} heroBox />;
          })} 
      </ScrollContainer>
    </div>
  );
};
export default HeroBox;
