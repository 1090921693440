import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { GatsbyImage } from "gatsby-plugin-image"
// import { makeStyles } from '@material-ui/core/styles';

const query = graphql`
  {
    TotalPosts: allPrismicBlogPost {
      nodes {
        slug: uid
        data {
          title {
            text
          }
          featured_image {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
    allPageViews {
      nodes {
        id
        last30DayViews
      }
    }
    allFile(filter: {relativePath: {regex: "/standard_featured/"}}) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`;


// const useStyles = makeStyles({
//   root: {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'space-between',
//     minWidth: 250,
//     marginBottom: '1rem',
//     '@media (min-width: 768px)': {
//       marginRight: '1.5rem',
//       maxWidth: 'calc(31.5%)',

//       '&:last-child': {
//         marginRight: 0,
//       },
//     },
//   },
//   bullet: {
//     display: 'inline-block',
//     margin: '0 2px',
//     transform: 'scale(0.8)',
//   },
//   title: {
//     fontSize: 14,
//   },
//   pos: {
//     marginBottom: 12,
//   },
// });




export default function Top4PostsImages() {
  const {
    TotalPosts: { nodes: allPosts },
    allPageViews: { nodes: allViews }
  } = useStaticQuery(query);

  function* filter(array, condition, maxSize) {
    if (!maxSize || maxSize > array.length) {
      maxSize = array.length;
    }
    let count = 0;
    let i = 0;
    while (count < maxSize && i < array.length) {
      const check = condition(array[i]);
      if (check) {
        yield check;
        count++;
      }
      i++;
    }
  }

  const posts = Array.from(
    filter(
      allViews,
      all => {
        const post = allPosts.filter(
          post => post.slug === all.id.replace('/', '')
        );
        if (post.length > 0) {
          return post[0];
        }
        return false;
      },
      3
    )
  );


  return (
    <div className="mt-6 border-t-8 border-themeBrandColor">
      <h2 className="text-center my-8">Artículos Populares</h2>
      <div className="grid md:grid-cols-3 gap-6 border-gray-300 mx-4"> 
        {posts.map((post, i) => (
          <Card className="">
          
          <CardContent>
        
          <Link
            to={`/${post.slug}/`}
            key={i}
            className=" mb-4 capitalize block text-sm"
          >
            
            {post.data.featured_image && post.data.featured_image.gatsbyImageData && <GatsbyImage image={post.data.featured_image.gatsbyImageData}/>}
            
            
            
           <h3 className="mb-4">{post.data.title.text}</h3>
            
          </Link>
 
          </CardContent>
          
        </Card>
        ))}
  
          
  
  
  

      </div>
     
      
    </div>
  );
}
